import React, { useEffect, useState, useCallback } from "react";
import AdminHeader from "../componentes/AdminHeader";
import AdminNavBar from "../componentes/AdminNavBar";
import { createGlobalStyle } from  "styled-components";
import EditorConvertToHTML from "../componentes/Draft";
import PropTypes from 'prop-types';
import axios from 'axios';
import { EditorState, convertToRaw, Modifier, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DOMPurify from 'dompurify';
import { useParams } from "react-router-dom";
import Loader from "../componentes/Loader";
import Cookies from 'js-cookie';

export const GlobalStyle = createGlobalStyle`
:root{
  --header-height: 3rem;
  --nav-width: 68px;
  --first-color: #afa939;
  --first-color-light: #ffffff;
  --black-color: #000000;
  --body-font: 'Nunito', sans-serif;
  --normal-font-size: 1rem;
  --z-fixed: 100}*,::before,::after{box-sizing: border-box}
  
  body{
      position: relative;
      margin: var(--header-height) 0 0 0;
      padding: 0 1rem;
      font-family: var(--body-font);
      font-size: var(--normal-font-size);
      transition: .5s}
      a{text-decoration: none}
      .header{
          width: 100%;
          height: var(--header-height);
          position: fixed;top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 1rem;
          background-color: var(--black-color);
          z-index: var(--z-fixed);
          transition: .5s}
          .header_toggle{color: var(--first-color);
              font-size: 1.5rem;cursor: pointer}
              .header_img{
                  width: 35px;
                  height: 35px;
                  display: flex;
                  justify-content: center;
                  border-radius: 50%;
                  overflow: hidden}
                  .header_img img{width: 40px}
                  .l-navbar{
                      position: fixed;
                      top: 0;
                      left: -30%;
                      width: var(--nav-width);
                      height: 100vh;
                      background-color: var(--first-color);
                      padding: .5rem 1rem 0 0;
                      transition: .5s;
                      z-index: var(--z-fixed)}
                      .nav{
                          height: 100%;
                          display: flex;
                          flex-direction: column;
                          justify-content: space-between;
                          overflow: hidden}
                          .nav_logo,
                           .nav_link{
                              display: grid;
                              grid-template-columns: max-content max-content;
                              align-items: center;
                              column-gap: 1rem;
                              padding: .5rem 0 .5rem 1.5rem}
                              .nav_logo{
                                  margin-bottom: 2rem}
                              .nav_logo-icon{
                                  font-size: 1.25rem;
                                  color: var(--black-color)}
                                  .nav_logo-name{
                                      color: var(--black-color);
                                      font-weight: 700}
                                      .nav_link{
                                          position: relative;
                                          color: var(
                                              --first-color-light);
                                              margin-bottom: 1.5rem;
                                              transition: .3s}
                                          .nav_link:hover{
                                              color: var(--black-color)}
                                              .nav_icon{
                                                  font-size: 1.25rem}
                                                  .show{
                                                      left: 0}
                                                      .body-pd{
                                                          padding-left: calc(var(--nav-width) + 1rem)}
                                                          .active{color: var(--black-color)}
                                                          .active::before{content: '';
                                                          position: absolute;
                                                          left: 0;
                                                          width: 2px;
                                                          height: 32px;
                                                          background-color: var(--black-color)}
                                                          .height-100{height:100vh}
                                                          @media screen and (min-width: 768px)
                                                          {
                                                              body{
                                                              margin: calc(var(--header-height) + 1rem) 0 0 0;
                                                              padding-left: calc(var(--nav-width) + 2rem)}
                                                              .header{height: calc(var(--header-height) + 1rem);
                                                              padding: 0 2rem 0 calc(var(--nav-width) + 2rem)}
                                                              .header_img{width: 40px;height: 40px}
                                                              .header_img img{width: 45px}
                                                              .l-navbar{left: 0;padding: 1rem 1rem 0 0}
                                                              .show{width: calc(var(--nav-width) + 156px)}
                                                              .body-pd{padding-left: calc(var(--nav-width) + 188px)}}
`
const customContentStateConverter = (contentState) => {
  // changes block type of images to 'atomic'
  const newBlockMap = contentState.getBlockMap().map((block) => {
      const entityKey = block.getEntityAt(0);
      if (entityKey !== null) {
          const entityBlock = contentState.getEntity(entityKey);
          const entityType = entityBlock.getType();
          switch (entityType) {
              case 'IMAGE': {
                  const newBlock = block.merge({
                      type: 'atomic',
                      text: 'img',
                  });
                  return newBlock;
              }
              default:
                  return block;
          }
      }
      return block;
  });
  const newContentState = contentState.set('blockMap', newBlockMap);
  return newContentState;
}

function EditarPostagem(){
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const [texto, setTexto] = useState('')
  const [uploadedImages, setUploadedImages] = useState([]);
  const [filesImages, setFilesImages] = useState([]);
  const [tagsImages, setTagsImages] = useState([]);
  const [novoTema, setTema] = useState('');
  const [temas, setTemas] = useState([]);
  const [titulo, setTitulo] = useState('');
  const [descricao, setDescricao] = useState('');
  const { tema } = useParams();
  const [auth, setAuth] = useState(false);
  const token = Cookies.get();
  EditorState.createEmpty()

  useEffect(() => {
    axios.post('/api/autenticacao', {token}, {withCredentials: true}).then((res)=>{
      setAuth(true)
    }).catch((error)=>{
      document.location = '/admin/login'
    })

    axios.post(`/api/blog/${tema}`).then((response) => {
      console.log(response.data)
      setTexto(response.data.blog.postagem)
      setEditorState(() => {
        const htmlContent = response.data.blog.postagem;
      /*const blocksFromHTML = convertFromHTML(htmlContent);
      const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
      );*/
      const textoOriginal = htmlContent.replace(/<p style="background-color: green; padding: 5px 10px; text-decoration: none; border-radius: 5px;" class="text-center"><a href="https:\/\/wa\.me\/\+5518997321170" style="color: white;">Fale com um especialista<\/a><span class="icon-whatsapp" style="margin-left: 5px; color: white;"><\/span><\/p>/g, '[===fale com um especialista===]');
      const blocksFromHTML = convertFromHTML(textoOriginal);
      var editorState = EditorState.createWithContent(customContentStateConverter(
      ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
    )
    ))
      return editorState;
    })
      let loadTemas = []
      if(Array.isArray(response.data.metaTags)){
        for(var cont=0; cont<response.data.metaTags.length; cont++){
            loadTemas.push(response.data.metaTags[cont].tema)
        }
      }
      setTemas(loadTemas)
      setDescricao(response.data.blog.descricao)
      setTitulo(response.data.blog.titulo)
      
    }).catch((error) => {
      console.log(error);
    });
  }, []);

  const uploadImageCallBack = useCallback((file) => {
    
    setFilesImages(prevArray => [...prevArray, file])

    const imageObject = {
      file: file,
      localSrc: URL.createObjectURL(file),
    };

    setUploadedImages(prevArray => [...prevArray, imageObject]);
    console.log('------>><<')
    console.log(imageObject.localSrc)

    // We need to return a promise with the image src
    // the img src we will use here will be what's needed
    // to preview it in the browser. This will be different than what
    // we will see in the index.md file we generate.
    return new Promise((resolve) => {
      resolve({ data: { link: imageObject.localSrc } });
    });
  }, [uploadedImages]);

  if(!auth){
    return <Loader />
  }

  function inputTema(event) {
    const { value } = event.target;
    console.log(novoTema)
    setTema(value);
  }

  function adicionarTemas(){
    setTemas([...temas, novoTema]);
  }

  function deletarTema(index){
    console.log('acionou')
    const novaLista = [...temas];
    novaLista.splice(index, 1); // Remove o elemento no índice especificado
    setTemas(novaLista); // Atualiza o estado com a nova lista
}

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);

    // Atualizar os estados filesImages e uploadedImages
    updateImageStates(draftToHtml(convertToRaw(newEditorState.getCurrentContent())));
    // Adicione o console.log aqui
    console.log('============>')
    console.log(texto)
    console.log(filesImages)
    console.log(uploadedImages)
    let novoTexto = draftToHtml(convertToRaw(newEditorState.getCurrentContent()))
    novoTexto = novoTexto.replace(/(<img[^>]*>)(?=\s*<img)/g, '$1<p></p>');
    setTexto(novoTexto.replace(/\[===fale com um especialista===\]/g, '<p style="background-color: green; padding: 5px 10px; text-decoration: none; border-radius: 5px;" class="text-center"><a href="https://wa.me/+5518997321170" style="color: white;">Fale com um especialista</a><span class="icon-whatsapp" style="margin-left: 5px; color: white;"></span></p>'))
    
    // Atrasar a execução da manipulação de imagens em 100 milissegundos
    setTimeout(() => {
      // Seleciona a div com a classe especificada
      let divElement = document.querySelector('#preview');

      // Seleciona todas as imagens dentro da div
      let imagens = divElement.querySelectorAll('img');

      // Itera sobre todas as imagens e adiciona a classe 'img-fluid'
      imagens.forEach(function(imagem) {
          imagem.classList.add('img-fluid');
      });
  }, 100); // 100 milissegundos de atraso
  };

  
  
  const updateImageStates = (textHtml) => {

console.log('==========================')
// Expressão regular para encontrar tags <img> no texto
  const regext = /<img[^>]*\bsrc="([^"]*blob[^"]*)"[^>]*>/g;
  console.log(textHtml)
  // Encontrar todas as correspondências no texto
  const matches = textHtml.match(regext);
  if(Array.isArray(matches)){
    console.log('?????????????????????')
     setTagsImages(matches)
  console.log(matches)
  console.log('tagsImages')
  console.log(tagsImages)
  }
  else{
    setTagsImages([])
  }

    console.log(textHtml)
    console.log(uploadedImages)
    // Filtra apenas as imagens que ainda estão presentes no HTML
    const updatedUploadedImages = uploadedImages.filter((image) =>
      textHtml.includes(image.localSrc)
    );
   
    // Atualiza o estado uploadedImages
    setUploadedImages(updatedUploadedImages);

    let newArrayFiles = []
    for(let i=0; i<updatedUploadedImages.length; i++){
      newArrayFiles.push(updatedUploadedImages[i].file)
    }

    setFilesImages(newArrayFiles)
  };
  
 


  async function enviar() {
    
    const arquivoSelecionado = document.querySelector('#imagem-blog').files[0];
    const titulo = document.querySelector('#titulo').value;
    const descricao = document.querySelector('#descricao').value;

    /*if (!arquivoSelecionado) {
      alert('Selecione um arquivo antes de enviar.');
      return;
    }*/

    if (!titulo) {
      alert('Descreva um título antes de enviar.');
      return;
    }

    if (!descricao) {
      alert('Descreva uma descrição antes de enviar.');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('upload', arquivoSelecionado);
      for (const file of filesImages) {
        formData.append('imagensBlog', file);
      }
      formData.append('titulo', titulo);
      formData.append('descricao', descricao);
      formData.append('postagem', texto);
      formData.append('token', token.Chocolate);
      
      for (let i=0; i<tagsImages.length; i++){
        formData.append('imgTags', tagsImages[i]);
      }
      for (let c=0; c<temas.length; c++){
        formData.append('temas', temas[c]);
      }
      
      console.log('exp')
      console.log(tagsImages)
      console.log('formData')
      console.log(formData.getAll('imagensBlog'))
      console.log(filesImages)
      const response = await axios.post(`/api/editar/postagem/${tema}`, formData, {withCredentials: true, headers: {
        "Content-Type": "multipart/form-data"
      }});
      window.location = '/admin/blog'
      
      // Lógica de tratamento em caso de sucesso
    } catch (error) {
      console.log('error: '+error)
      alert('Erro ao enviar o formulário:', error);
      // Lógica de tratamento em caso de erro
    }
  };  

  const CustomOption = ({ onChange, editorState }) => {
    const addWhats = () => {
      const contentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        '[===fale com um especialista===]',
        editorState.getCurrentInlineStyle(),
      );
      onChange(EditorState.push(editorState, contentState, 'insert-characters'));

    };
  
    return (
      <div onClick={addWhats}>📞</div>
    );
  };

  CustomOption.propTypes = {
    onChange: PropTypes.func,
    editorState: PropTypes.object,
  };

   // Função para atualizar o estado 'titulo'
   const handleTituloChange = (event) => {
    setTitulo(event.target.value);
};
   const handleDescricaoChange = (event) => {
    setDescricao(event.target.value);
};

    return(
      <>
      <GlobalStyle />
        <div>
          <AdminHeader/>
          <AdminNavBar/>
               
        {/*Container Main start*/}
<div className="height-100 bg-light" id="admin">
  <div className="card" id="editor">
    <div className="card-header text-white bg-dark">
      Nova Postagem
    </div>
    <div className="card-body">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <label>Imagem do Card</label>
            <input type="file" className="form-control" id="imagem-blog"/>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <label>Título</label>
            <input className="form-control" name="titulo" id="titulo" type="text" value={titulo} onChange={handleTituloChange}  />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <label>Descrição</label>
            <textarea className="form-control" name="descricao" id="descricao" value={descricao} onChange={handleDescricaoChange} />
          </div>
        </div>
        <div className="row">
            <div className="col-6">
                <label>Temas do artigo</label>
                <div class="input-group mb-3">
                  <input type="text" class="form-control" name="rotacao" value={novoTema}  onChange={inputTema} />
                  <button class="btn btn-outline-secondary" type="button" id="button-addon2" onClick={adicionarTemas}><span className="icon-plus"> Adicionar</span></button>
                </div>
            </div>
        </div>
        <hr></hr>
        <div className="row">
        <div className="col-6">
  <label>Rotações selecionadas</label>
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    {temas.map((res, index) => (
      <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ margin: '10px' }}>{res}</span>
        <button className="btn btn-danger" style={{ marginLeft: '5px' }} onClick={()=>deletarTema(index)}>
          <span className="icon-trash"></span>
        </button>
      </div>
    ))}
  </div>
</div>
        </div>
        <div className="row" style={{marginTop: 20}}>
          <div className="col-12">
          <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
          image: { uploadCallback: uploadImageCallBack,
                   className: "img-fluid",
                   defaultSize: {
                    height: 'auto',
                    width: 'auto',
                  }},
          inputAccept:
            'application/pdf,text/plain,application/vnd.openxmlformatsofficedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel',
        }}
        toolbarCustomButtons={[<CustomOption />]}
      />
      
      <textarea
        disabled
        value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
      />
          </div>
        </div>
        <div className="row">
          <div className="col-12"> 
            <button className="btn bg-info text-white" type="button" onClick={enviar}>Confirmar</button>
            <button style={{marginLeft: 5}} className="btn bg-danger text-white" type="button" onClick={()=>window.location ='/admin/blog'}>Cancelar</button>
          </div>
        </div>
        <hr></hr>
        <section className="ftco-section ftco-degree-bg">
          <div className="container">  
          <div className="row">
          <div className="col-lg-8 ftco-animate fadeInUp ftco-animated" style={{backgroundColor: "gainsboro"}}>
            <h1>Preview</h1>
            <div id="preview" dangerouslySetInnerHTML={{ __html: texto }} />
          </div>
        </div>
          </div>
        </section>
      
      </div>
    </div>
  </div>
</div>

        </div>
        </>
    )
}

export default EditarPostagem;
