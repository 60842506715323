import React, { useEffect, useState } from "react";
import AdminHeader from "../componentes/AdminHeader";
import AdminNavBar from "../componentes/AdminNavBar";
import { createGlobalStyle } from  "styled-components";
import axios from "axios";
import Loader from "../componentes/Loader";
import Cookies from 'js-cookie';



export const GlobalStyle = createGlobalStyle`
:root{
  --header-height: 3rem;
  --nav-width: 68px;
  --first-color: #afa939;
  --first-color-light: #ffffff;
  --black-color: #000000;
  --body-font: 'Nunito', sans-serif;
  --normal-font-size: 1rem;
  --z-fixed: 100}*,::before,::after{box-sizing: border-box}
  
  body{
      position: relative;
      margin: var(--header-height) 0 0 0;
      padding: 0 1rem;
      font-family: var(--body-font);
      font-size: var(--normal-font-size);
      transition: .5s}
      a{text-decoration: none}
      .header{
          width: 100%;
          height: var(--header-height);
          position: fixed;top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 1rem;
          background-color: var(--black-color);
          z-index: var(--z-fixed);
          transition: .5s}
          .header_toggle{color: var(--first-color);
              font-size: 1.5rem;cursor: pointer}
              .header_img{
                  width: 35px;
                  height: 35px;
                  display: flex;
                  justify-content: center;
                  border-radius: 50%;
                  overflow: hidden}
                  .header_img img{width: 40px}
                  .l-navbar{
                      position: fixed;
                      top: 0;
                      left: -30%;
                      width: var(--nav-width);
                      height: 100vh;
                      background-color: var(--first-color);
                      padding: .5rem 1rem 0 0;
                      transition: .5s;
                      z-index: var(--z-fixed)}
                      .nav{
                          height: 100%;
                          display: flex;
                          flex-direction: column;
                          justify-content: space-between;
                          overflow: hidden}
                          .nav_logo,
                           .nav_link{
                              display: grid;
                              grid-template-columns: max-content max-content;
                              align-items: center;
                              column-gap: 1rem;
                              padding: .5rem 0 .5rem 1.5rem}
                              .nav_logo{
                                  margin-bottom: 2rem}
                              .nav_logo-icon{
                                  font-size: 1.25rem;
                                  color: var(--black-color)}
                                  .nav_logo-name{
                                      color: var(--black-color);
                                      font-weight: 700}
                                      .nav_link{
                                          position: relative;
                                          color: var(
                                              --first-color-light);
                                              margin-bottom: 1.5rem;
                                              transition: .3s}
                                          .nav_link:hover{
                                              color: var(--black-color)}
                                              .nav_icon{
                                                  font-size: 1.25rem}
                                                  .show{
                                                      left: 0}
                                                      .body-pd{
                                                          padding-left: calc(var(--nav-width) + 1rem)}
                                                          .active{color: var(--black-color)}
                                                          .active::before{content: '';
                                                          position: absolute;
                                                          left: 0;
                                                          width: 2px;
                                                          height: 32px;
                                                          background-color: var(--black-color)}
                                                          .height-100{height:100vh}
                                                          @media screen and (min-width: 768px)
                                                          {
                                                              body{
                                                              margin: calc(var(--header-height) + 1rem) 0 0 0;
                                                              padding-left: calc(var(--nav-width) + 2rem)}
                                                              .header{height: calc(var(--header-height) + 1rem);
                                                              padding: 0 2rem 0 calc(var(--nav-width) + 2rem)}
                                                              .header_img{width: 40px;height: 40px}
                                                              .header_img img{width: 45px}
                                                              .l-navbar{left: 0;padding: 1rem 1rem 0 0}
                                                              .show{width: calc(var(--nav-width) + 156px)}
                                                              .body-pd{padding-left: calc(var(--nav-width) + 188px)}}
`

function Blog(){
  
  const token = Cookies.get();
  const [blogs, setBlogs] = useState([]);
  const [auth, setAuth] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    console.log(token)
    const cookiesString = document.cookie;
    console.log(cookiesString)
    axios.post('/api/autenticacao', {token}, {withCredentials: true}).then((res)=>{
      setAuth(true)
    }).catch((error)=>{
        console.log(error)
        document.location = '/admin/login'
    })
    axios.get('/api/todos/artigos').then((response) => {
      setBlogs(response.data.blogs);
    }).catch((error) => {
      console.log(error);
    });
  }, []);

  if(!auth){
    return <Loader />
  }

    // Exemplo de lista de artigos
  const allArticles = blogs;

  
  let articlesPerPage; // Número de artigos por página
  if(blogs.length<6){
    articlesPerPage = blogs.length
  }
  else{
    articlesPerPage = 6
  }
  // Calcula o índice inicial e final dos artigos a serem exibidos na página atual
  const indexOfLastArticle = Math.min(currentPage * articlesPerPage, allArticles.length);
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;

  console.log(indexOfFirstArticle)
  console.log(indexOfLastArticle)
  const currentArticles = allArticles.slice(indexOfFirstArticle, indexOfLastArticle);
  console.log(currentArticles)
  
  // Função para exibir os próximos artigos
  const nextPage = () => {
    if (currentPage < allArticles.length/6) {
      setCurrentPage(currentPage + 1);
      console.log(currentPage+1)
    }
  };

  // Função para exibir os artigos com base no número
  const numberPage = (index) => {
      console.log('index: ', index)
      setCurrentPage(index);
  };

  // Função para exibir os artigos anteriores
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      console.log(currentPage-1)
    }
  };

  const renderedItems = [];

  // Para estilos de hover
  const linkHoverStyle = {
    cursor: 'pointer',
  };

  for (let i = 1; i <= Math.ceil(allArticles.length/6); i++) {
    // Adicione um elemento JSX ao array com uma chave única
    renderedItems.push(<li className={currentPage== i ? "active": ''}><span onClick={()=>numberPage(i)} style={linkHoverStyle}>{i}</span></li>);
  }

  const monthNames = [
    'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
    'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
  ];
  
  function deleteArtigo(id){
    axios.post(`/api/delete/blog/${id}`, {token}).then((resp)=>{
      window.location = '/admin/blog';
    }).catch((err)=>{
      alert(err)
    })
  }

    return(
      <>
      <GlobalStyle />
        <div>
          <AdminHeader/>
          <AdminNavBar/>
               
        {/*Container Main start*/}
<div className="height-100 bg-light" id="admin">
  <div className="card" id="editor">
    <div className="card-header text-white bg-dark text-center">
      Minhas postagens
    </div>
    <div className="card-body">
      <div className="container">
      <div className="row">
        <a href="/admin/novaPostagem"><button className="btn bg-success text-white">Novo Post <span className="icon-plus" /></button></a>
      </div>
      <div className="row d-flex">
      {currentArticles.map((article, index) => (

<div className="col-md-4 d-flex">
<div className="blog-entry justify-content-end">
  <div className="text px-4 py-4">
    <h3 className="heading mb-0"><a href={`/blog/artigo/${article.parametro}`}>{article.titulo}</a></h3>
  </div>
  <a href={`/blog/artigo/${article.parametro}`} className="block-20" style={{backgroundImage: `url(http://douglascruzadvogado.com.br/api/imagem/${article.dirimg})`}}>
  </a>
  <div className="text p-4 float-right d-block">
    <div className="topper d-flex align-items-center">
      <div className="one py-2 pl-3 pr-1 align-self-stretch">
        <span className="day">{new Date(article.updatedAt).getDate()}</span>
      </div>
      <div className="two pl-0 pr-3 py-2 align-self-stretch">
        <span className="yr">{new Date(article.updatedAt).getFullYear()}</span>
        <span className="mos">{monthNames[new Date(article.updatedAt).getMonth()]}</span>
      </div>
    </div>
    <p style={{ width: '280px' }}>{article.descricao}</p>
    <div className="row">
            <p><a href={`/admin/editar/postagem/${article.parametro}`} className="btn btn-info"><span className="icon-edit" /> Editar</a></p>
            <p><a href={`/blog/artigo/${article.parametro}`} className="btn" style={{backgroundColor: 'blue', color: 'white', marginLeft: 5, marginRight: 5}}> <span className="icon-eye" /> Visualizar</a></p>
            <p><button className="btn btn-danger" onClick={()=>deleteArtigo(article.id)}><span className="icon-trash" /> Excluir</button></p>
        </div>
  </div>
</div>
</div>
))}
</div>
<div className="row mt-5">
      <div className="col text-center">
        <div className="block-27">
          <ul>
            <li><a onClick={prevPage} disabled={currentPage === 1} style={linkHoverStyle}>&lt;</a></li>
            {renderedItems}
            <li><span onClick={nextPage} disabled={indexOfLastArticle >= allArticles.length/6} style={linkHoverStyle}>&gt;</span></li>
          </ul>
        </div>
      </div>
    </div>

      </div>
    </div>
  </div>
</div>

        </div>
        </>
    )
}

export default Blog;
