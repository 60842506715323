function CardArea(){

    return(
        <section className="ftco-section">
  <div className="container">
    <div className="row d-flex justify-content-center">
      <div className="col-md-3 text-center">
        <div className="practice-area ftco-animate">
          <div className="icon d-flex justify-content-center align-items-center">
            <span className="flaticon-family" />
          </div>
          <h3>Lei de família</h3>
          <p style={{textAlign: 'justify'}}>A lei de família é um ramo essencial do sistema jurídico que lida com questões profundamente pessoais e emocionais relacionadas às famílias.</p>
          
        </div>
      </div>
      <div className="col-md-3 text-center">
        <div className="practice-area ftco-animate">
          <div className="icon d-flex justify-content-center align-items-center">
            <span className="flaticon-auction" />
          </div>
          <h3>Trabalhista</h3>
          <p style={{textAlign: 'justify'}}>O direito trabalhista é uma área jurídica crucial que lida com as relações entre empregadores e empregados.</p>
         
        </div>
      </div>
      <div className="col-md-3 text-center">
        <div className="practice-area ftco-animate">
          <div className="icon d-flex justify-content-center align-items-center">
            <span className="flaticon-shield" />
          </div>
          <h3>Seguros</h3>
          <p style={{textAlign: 'justify'}}>O direito de seguro é uma área legal que se concentra nas relações entre seguradoras, segurados e beneficiários de apólices de seguro.</p>
          
        </div>
      </div>
      <div className="col-md-3 text-center">
        <div className="practice-area ftco-animate">
          <div className="icon d-flex justify-content-center align-items-center">
            <span className="flaticon-handcuffs" />
          </div>
          <h3>Criminalista</h3>
          <p style={{textAlign: 'justify'}}>O direito de seguro é uma área legal que se concentra nas relações entre seguradoras, segurados e beneficiários de apólices de seguro</p>
          
        </div>
      </div>
      <div className="col-md-3 text-center">
        <div className="practice-area ftco-animate">
          <div className="icon d-flex justify-content-center align-items-center">
            <span className="flaticon-house" />
          </div>
          <h3>Propriedades</h3>
          <p style={{textAlign: 'justify'}}>O direito de propriedade é um dos pilares fundamentais do sistema legal em todo o mundo e tem implicações significativas na vida das pessoas e na sociedade como um todo.</p>
          
        </div>
      </div>
      <div className="col-md-3 text-center">
        <div className="practice-area ftco-animate">
          <div className="icon d-flex justify-content-center align-items-center">
            <span className="flaticon-money" />
          </div>
          <h3>Finanças</h3>
          <p style={{textAlign: 'justify'}}>O direito financeiro é um campo jurídico que aborda questões relacionadas à gestão, regulamentação e fiscalização das finanças públicas e privadas.</p>
          
        </div>
      </div>
      <div className="col-md-3 text-center">
        <div className="practice-area ftco-animate">
          <div className="icon d-flex justify-content-center align-items-center">
            <span className="flaticon-medicine" />
          </div>
          <h3>Ofensas</h3>
          <p style={{textAlign: 'justify'}}>O direito de ofensas, muitas vezes referido como direito penal ou direito criminal, é uma área jurídica que lida com questões relacionadas a condutas criminosas, infrações e punições. </p>
          
        </div>
      </div>
      <div className="col-md-3 text-center">
        <div className="practice-area ftco-animate">
          <div className="icon d-flex justify-content-center align-items-center">
            <span className="flaticon-handcuffs" />
          </div>
          <h3>Ofensas sexuais</h3>
          <p style={{textAlign: 'justify'}}>O direito de ofensas sexuais é uma área específica do direito penal que lida com crimes de natureza sexual.</p>
          
        </div>
      </div>
    </div>
  </div>
</section>

    )
}

export default CardArea;