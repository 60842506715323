import React, { useEffect } from "react";
import AdminHeader from "../componentes/AdminHeader";
import AdminNavBar from "../componentes/AdminNavBar";
import { createGlobalStyle } from  "styled-components";



export const GlobalStyle = createGlobalStyle`
:root{
  --header-height: 3rem;
  --nav-width: 68px;
  --first-color: #afa939;
  --first-color-light: #ffffff;
  --black-color: #000000;
  --body-font: 'Nunito', sans-serif;
  --normal-font-size: 1rem;
  --z-fixed: 100}*,::before,::after{box-sizing: border-box}
  
  body{
      position: relative;
      margin: var(--header-height) 0 0 0;
      padding: 0 1rem;
      font-family: var(--body-font);
      font-size: var(--normal-font-size);
      transition: .5s}
      a{text-decoration: none}
      .header{
          width: 100%;
          height: var(--header-height);
          position: fixed;top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 1rem;
          background-color: var(--black-color);
          z-index: var(--z-fixed);
          transition: .5s}
          .header_toggle{color: var(--first-color);
              font-size: 1.5rem;cursor: pointer}
              .header_img{
                  width: 35px;
                  height: 35px;
                  display: flex;
                  justify-content: center;
                  border-radius: 50%;
                  overflow: hidden}
                  .header_img img{width: 40px}
                  .l-navbar{
                      position: fixed;
                      top: 0;
                      left: -30%;
                      width: var(--nav-width);
                      height: 100vh;
                      background-color: var(--first-color);
                      padding: .5rem 1rem 0 0;
                      transition: .5s;
                      z-index: var(--z-fixed)}
                      .nav{
                          height: 100%;
                          display: flex;
                          flex-direction: column;
                          justify-content: space-between;
                          overflow: hidden}
                          .nav_logo,
                           .nav_link{
                              display: grid;
                              grid-template-columns: max-content max-content;
                              align-items: center;
                              column-gap: 1rem;
                              padding: .5rem 0 .5rem 1.5rem}
                              .nav_logo{
                                  margin-bottom: 2rem}
                              .nav_logo-icon{
                                  font-size: 1.25rem;
                                  color: var(--black-color)}
                                  .nav_logo-name{
                                      color: var(--black-color);
                                      font-weight: 700}
                                      .nav_link{
                                          position: relative;
                                          color: var(
                                              --first-color-light);
                                              margin-bottom: 1.5rem;
                                              transition: .3s}
                                          .nav_link:hover{
                                              color: var(--black-color)}
                                              .nav_icon{
                                                  font-size: 1.25rem}
                                                  .show{
                                                      left: 0}
                                                      .body-pd{
                                                          padding-left: calc(var(--nav-width) + 1rem)}
                                                          .active{color: var(--black-color)}
                                                          .active::before{content: '';
                                                          position: absolute;
                                                          left: 0;
                                                          width: 2px;
                                                          height: 32px;
                                                          background-color: var(--black-color)}
                                                          .height-100{height:100vh}
                                                          @media screen and (min-width: 768px)
                                                          {
                                                              body{
                                                              margin: calc(var(--header-height) + 1rem) 0 0 0;
                                                              padding-left: calc(var(--nav-width) + 2rem)}
                                                              .header{height: calc(var(--header-height) + 1rem);
                                                              padding: 0 2rem 0 calc(var(--nav-width) + 2rem)}
                                                              .header_img{width: 40px;height: 40px}
                                                              .header_img img{width: 45px}
                                                              .l-navbar{left: 0;padding: 1rem 1rem 0 0}
                                                              .show{width: calc(var(--nav-width) + 156px)}
                                                              .body-pd{padding-left: calc(var(--nav-width) + 188px)}}
`

function AreaAtuacao(){

 
    return(
      <>
      <GlobalStyle />
        <div>
          <AdminHeader/>
          <AdminNavBar/>
               
        {/*Container Main start*/}
<div className="height-100 bg-light" id="admin">
  <div className="card" id="editor">
    <div className="card-header text-white bg-dark text-center">
      Minhas áreas de atuação
    </div>
    <div className="card-body">
      <div className="container">
      <div className="row" style={{marginBottom: 10}}>
        <a href="/admin/novaArea"><button className="btn bg-success text-white">Nova área <span className="icon-plus" /></button></a>
      </div>
      <div class="row d-flex justify-content-center">
        	<div class="col-md-4 text-center">
        		<div class="practice-area ftco-animate fadeInUp ftco-animated">
        			<div class="icon d-flex justify-content-center align-items-center">
        				<span class="flaticon-family"></span>
        			</div>
        			<h3>Lei de família</h3>
        			<p>A lei de família é um ramo essencial do sistema jurídico que lida com questões profundamente pessoais e emocionais relacionadas às famílias.</p>
        			<div className="row">
            <p><a href="#" className="btn btn-info"><span className="icon-edit" /> Editar</a></p>
            <p><a href="#" className="btn btn-danger"><span className="icon-trash" /> Excluir</a></p>
        </div>
        		</div>
        	</div>
        	<div class="col-md-4 text-center">
        		<div class="practice-area ftco-animate fadeInUp ftco-animated">
        			<div class="icon d-flex justify-content-center align-items-center">
        				<span class="flaticon-auction"></span>
        			</div>
        			<h3><a href="practice-single.html">Trabalhista</a></h3>
        			<p>O direito trabalhista é uma área jurídica crucial que lida com as relações entre empregadores e empregados.</p>
        			<div className="row">
            <p><a href="#" className="btn btn-info"><span className="icon-edit" /> Editar</a></p>
            <p><a href="#" className="btn" style={{backgroundColor: 'blue', color: 'white', marginLeft: 5, marginRight: 5}}> <span className="icon-eye" /> Visualizar</a></p>
            <p><a href="#" className="btn btn-danger"><span className="icon-trash" /> Excluir</a></p>
        </div>
        		</div>
        	</div>
        	<div class="col-md-4 text-center">
        		<div class="practice-area ftco-animate fadeInUp ftco-animated">
        			<div class="icon d-flex justify-content-center align-items-center">
        				<span class="flaticon-shield"></span>
        			</div>
        			<h3><a href="practice-single.html">Seguros</a></h3>
        			<p>O direito de seguro é uma área legal que se concentra nas relações entre seguradoras, segurados e beneficiários de apólices de seguro.</p>
        			<div className="row">
            <p><a href="#" className="btn btn-info"><span className="icon-edit" /> Editar</a></p>
            <p><a href="#" className="btn" style={{backgroundColor: 'blue', color: 'white', marginLeft: 5, marginRight: 5}}> <span className="icon-eye" /> Visualizar</a></p>
            <p><a href="#" className="btn btn-danger"><span className="icon-trash" /> Excluir</a></p>
        </div>
        		</div>
        	</div>
        	<div class="col-md-4 text-center">
        		<div class="practice-area ftco-animate fadeInUp ftco-animated">
        			<div class="icon d-flex justify-content-center align-items-center">
        				<span class="flaticon-handcuffs"></span>
        			</div>
        			<h3><a href="practice-single.html">Criminalista</a></h3>
        			<p>O direito de seguro é uma área legal que se concentra nas relações entre seguradoras, segurados e beneficiários de apólices de seguro</p>
        			<div className="row">
            <p><a href="#" className="btn btn-info"><span className="icon-edit" /> Editar</a></p>
            <p><a href="#" className="btn" style={{backgroundColor: 'blue', color: 'white', marginLeft: 5, marginRight: 5}}> <span className="icon-eye" /> Visualizar</a></p>
            <p><a href="#" className="btn btn-danger"><span className="icon-trash" /> Excluir</a></p>
        </div>
        		</div>
        	</div>
        	<div class="col-md-4 text-center">
        		<div class="practice-area ftco-animate fadeInUp ftco-animated">
        			<div class="icon d-flex justify-content-center align-items-center">
        				<span class="flaticon-house"></span>
        			</div>
        			<h3><a href="practice-single.html">Propriedades</a></h3>
        			<p>O direito de propriedade é um dos pilares fundamentais do sistema legal em todo o mundo e tem implicações significativas na vida das pessoas e na sociedade como um todo.</p>
        			<div className="row">
            <p><a href="#" className="btn btn-info"><span className="icon-edit" /> Editar</a></p>
            <p><a href="#" className="btn" style={{backgroundColor: 'blue', color: 'white', marginLeft: 5, marginRight: 5}}> <span className="icon-eye" /> Visualizar</a></p>
            <p><a href="#" className="btn btn-danger"><span className="icon-trash" /> Excluir</a></p>
        </div>
        		</div>
        	</div>
        	<div class="col-md-4 text-center">
        		<div class="practice-area ftco-animate fadeInUp ftco-animated">
        			<div class="icon d-flex justify-content-center align-items-center">
        				<span class="flaticon-money"></span>
        			</div>
        			<h3><a href="practice-single.html">Finanças</a></h3>
        			<p>O direito financeiro é um campo jurídico que aborda questões relacionadas à gestão, regulamentação e fiscalização das finanças públicas e privadas.</p>
        			<div className="row">
            <p><a href="#" className="btn btn-info"><span className="icon-edit" /> Editar</a></p>
            <p><a href="#" className="btn" style={{backgroundColor: 'blue', color: 'white', marginLeft: 5, marginRight: 5}}> <span className="icon-eye" /> Visualizar</a></p>
            <p><a href="#" className="btn btn-danger"><span className="icon-trash" /> Excluir</a></p>
        </div>
        		</div>
        	</div>
        	<div class="col-md-4 text-center">
        		<div class="practice-area ftco-animate fadeInUp ftco-animated">
        			<div class="icon d-flex justify-content-center align-items-center">
        				<span class="flaticon-medicine"></span>
        			</div>
        			<h3><a href="practice-single.html">Ofensas</a></h3>
        			<p>O direito de ofensas, muitas vezes referido como direito penal ou direito criminal, é uma área jurídica que lida com questões relacionadas a condutas criminosas, infrações e punições. </p>
        			<div className="row">
            <p><a href="#" className="btn btn-info"><span className="icon-edit" /> Editar</a></p>
            <p><a href="#" className="btn" style={{backgroundColor: 'blue', color: 'white', marginLeft: 5, marginRight: 5}}> <span className="icon-eye" /> Visualizar</a></p>
            <p><a href="#" className="btn btn-danger"><span className="icon-trash" /> Excluir</a></p>
        </div>
        		</div>
        	</div>
        	<div class="col-md-4 text-center">
        		<div class="practice-area ftco-animate fadeInUp ftco-animated">
        			<div class="icon d-flex justify-content-center align-items-center">
        				<span class="flaticon-handcuffs"></span>
        			</div>
        			<h3><a href="practice-single.html">Ofensas sexuais</a></h3>
        			<p>O direito de ofensas sexuais é uma área específica do direito penal que lida com crimes de natureza sexual.</p>
        			<div className="row">
            <p><a href="#" className="btn btn-info"><span className="icon-edit" /> Editar</a></p>
            <p><a href="#" className="btn" style={{backgroundColor: 'blue', color: 'white', marginLeft: 5, marginRight: 5}}> <span className="icon-eye" /> Visualizar</a></p>
            <p><a href="#" className="btn btn-danger"><span className="icon-trash" /> Excluir</a></p>
        </div>
        		</div>
        	</div>
        </div>
      </div>
    </div>
  </div>
</div>

        </div>
        </>
    )
}

export default AreaAtuacao;