import React from "react";

function AdminHeader(){

    function teste(){
      const storedValue = localStorage.getItem('isChecked');
      let newValue;
      if(storedValue==null || JSON.parse(storedValue)){
        newValue = false
      }
      else{
        newValue = true
      }
      console.log('///////////')
      console.log(newValue)
      // Armazenar novo valor no localStorage
      localStorage.setItem('isChecked', newValue);
}

    return(
      <header className="header" id="header">
  <div className="header_toggle" onClick={teste}> <i className="bx bx-menu" id="header-toggle" /> </div>
  <div className="header_img"> <img src="/images/doug-2.jpg" alt /> </div>
</header>

    )
}

export default AdminHeader;