import React from "react";

function Servicos({cabecalho, contatos}){

    return(
      <section className="ftco-section ftco-no-pt" id="admin">
  <div className="container">
    <div className="row">
      <div className="col-lg-3 py-5">
        <div className="heading-section ftco-animate">
          <span className="subheading">Serviços</span>
          <h2 className="mb-4">Por que nos escolher?</h2>
          <p><a href={`https://wa.me/${contatos.whatsapp}?text=${contatos.textoWhatsapp}` } target="_blank" className="btn btn-primary py-3 px-4"><span className="icon-whatsapp" /> Whatsapp</a></p>
        </div>
      </div>
      <div className="col-lg-9 services-wrap px-4 pt-5">
        <div className="row pt-md-3">
          <div className="col-md-4 d-flex align-items-stretch">
            <div className="services text-center">
              <div className="icon d-flex justify-content-center align-items-center">
                <span className="flaticon-lawyer" />
              </div>
              <div className="text">
                <h3>{cabecalho.tituloPrimeiroCard}</h3>
                <p>{cabecalho.primeiroCard}</p>
              </div>
              
            </div>
          </div>
          <div className="col-md-4 d-flex align-items-stretch">
            <div className="services text-center">
              <div className="icon d-flex justify-content-center align-items-center">
                <span className="flaticon-lawyer" />
              </div>
              <div className="text">
                <h3>{cabecalho.tituloSegundoCard}</h3>
                <p>{cabecalho.segundoCard}</p>
              </div>
             
            </div>
          </div>
          <div className="col-md-4 d-flex align-items-stretch">
            <div className="services text-center">
              <div className="icon d-flex justify-content-center align-items-center">
                <span className="flaticon-lawyer" />
              </div>
              <div className="text">
                <h3>{cabecalho.tituloTerceiroCard}</h3>
                <p>{cabecalho.terceiroCard}</p>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


    )
}

export default Servicos
