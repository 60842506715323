import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './paginas/Home';
import MyEditor from './paginas/Draft';
import CriarCardBlog from './paginas/CriarCardBlog';
import EditorConvertToHTML from './componentes/Draft';
import Blog from './paginas/Blog';
import AreaAtuacao from './paginas/AreaAtuacao';
import CriarArea from './paginas/CriarArea';
import CriarDepoimento from './paginas/CriarDepoimento';
import Depoimento from './paginas/Depoimentos';
import QuemSomos from './paginas/QuemSomos';
import Contatos from './paginas/Contatos';
import Cabecalho from './paginas/Cabecalho';
import Somos from './paginas/Somos';
import Atuacoes from './paginas/Atuacoes';
import BlogsGerais from './paginas/BlogsGerais';
import BlogsCategoria from './paginas/BlogsCategoria';
import ApresentacaoArtigoBlog from './paginas/ApresentacaoArtigoBlog';
import ApresentacaoAreaAtuacao from './paginas/ApresentacaoAreaAtuacao';
import EditarPostagem from './paginas/EditarPostagem';
import EditarDepoimento from './paginas/EditarDepoimento';
import Login from './paginas/Login'
import LoginVerify from './paginas/LoginVerify'

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path='/' exact element={<Home />}/>
      <Route path='/admin/blog' exact element={<Blog/>}/>
      <Route path='/admin/novaPostagem' exact element={<CriarCardBlog />}/>
      <Route path='/admin/editar/postagem/:tema' exact element={<EditarPostagem />}/>
      <Route path='/admin/novoDepoimento' exact element={<CriarDepoimento />}/>
      <Route path='/admin/editarDepoimento/:id' exact element={<EditarDepoimento />}/>
      <Route path='/admin/depoimento' exact element={<Depoimento />}/>
      <Route path='/admin/quemSomos' exact element={<QuemSomos />}/>
      <Route path='/admin/contatos' exact element={<Contatos />}/>
      <Route path='/admin/cabecalho' exact element={<Cabecalho />}/>
      <Route path='/areasAtuacao' exact element={<Atuacoes />}/>
      <Route path='/blog' exact element={<BlogsGerais/>}/>
      <Route path='/blog/categoria/:tema' exact element={<BlogsCategoria/>}/>
      <Route path='/blog/artigo/:tema' exact element={<ApresentacaoArtigoBlog/>}/>
      <Route path='/admin/login' exact element={<Login/>}/>
      <Route path='/admin/login/verify' exact element={<LoginVerify/>}/>
      <Route path="*" element={<>Não encontrado!</>} />
      
    </Routes>
  </BrowserRouter>
  );
}

export default App;
