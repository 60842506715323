import React, { useState } from 'react';
import { createGlobalStyle } from  "styled-components";
import axios from "axios";

export const GlobalStyle = createGlobalStyle`.login-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    margin: 50px auto 0;
  }
  
  h2 {
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    font-weight: bold;
  }
  
  input[type="text"],
  input[type="password"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  `

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    axios.post('/api/login', {password, username}).then((res)=>{
        window.location = '/admin/login/verify'
    }).catch((error)=>{
        console.log(error)
        if (error.response && error.response.status === 401) {
            // Tratar erro 401 (Não Autorizado) aqui
            alert('Erro de autenticação: Usuário ou senha incorretos.');
          } else if (error.response) {
            // O servidor respondeu com um status diferente de 401
            alert('Status do erro:', error.response.status);
            alert('Dados do erro:', error.response.data);
          } else if (error.request) {
            // A requisição foi feita, mas não recebeu resposta
            alert('Sem resposta do servidor:', error.request);
          } else {
            // Ocorreu um erro ao configurar a requisição
            alert('Erro ao configurar a requisição:', error.message);
          }
    })
  };

  return (
    <>
    <GlobalStyle />
  
    <div className="login-container">
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Login</button>
      </form>
    </div>
    </>
  );
}

export default Login;
