import React, { useEffect, useState } from "react";
import NavBar from "../componentes/NavBar";
import HeaderBlog from "../componentes/HeaderBlog";
import BlogArtigo from "../componentes/BlogArtigo";
import Rodape from "../componentes/Rodape";
import Loader from "../componentes/Loader";
import axios from "axios";

function ApresentacaoArtigoBlog(){
    const [resp, setResp] = useState(false);
  const [rotacoes, setRotacoes] = useState([]);
  const [cabecalho, setCabecalho] = useState(false);
  const [missao, setMissao] = useState(false);
  const [depoimentos, setDepoimentos] = useState(false);
  const [contatos, setContatos] = useState(false);
  const [areasAtuacao, setAreasAtuacao] = useState(false);
  

  useEffect(() => {
    axios.post('/api/dadosGerais').then((response) => {
      console.log(response.data);
      setResp(response.data);
      const novasRotacoes = response.data.rotacao.map((res) => res.texto);
      setRotacoes(novasRotacoes);
      console.log('-------------')
      console.log(response.data.cabecalho);
      console.log('-------------')
      setCabecalho(response.data.cabecalho);
      setMissao(response.data.missao);
      setDepoimentos(response.data.depoimentos);
      setContatos(response.data.contatos);
      setAreasAtuacao(response.data.areaAtuacao);
      setResp(true)
    }).catch((error) => {
      console.log(error);
    });
  }, []);

  if(!cabecalho || rotacoes.length==0 || !missao || !depoimentos || !contatos || !areasAtuacao){
    return <Loader />
  }
    return(
        <>
         <NavBar cabecalho={cabecalho} contatos={contatos} />
         <HeaderBlog />
         <BlogArtigo cabecalho={cabecalho} />
         <Rodape contatos={contatos} cabecalho={cabecalho} areasAtuacao={areasAtuacao} />
        </>
    )
}

export default ApresentacaoArtigoBlog;
