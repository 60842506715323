import React from "react";

function Rodape({contatos, cabecalho, areasAtuacao}){
  console.log('<--------------->')
  console.log(areasAtuacao)

    return(
      <footer className="ftco-footer ftco-bg-dark ftco-section">
  <div className="container">
    <div className="row mb-5">
      <div className="col-md">
        <div className="ftco-footer-widget mb-4">
          <h2 className="logo"><a href="#">{cabecalho.titulo} <span>{cabecalho.subTitulo}</span> <span style={{marginTop: 5}}>OAB: 501.670</span></a></h2>
          <p style={{textAlign: 'justify'}}>{cabecalho.textoCabelhoRodape}</p>
          <ul className="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
            <li className="ftco-animate"><a href={contatos.linkTwitter}><span className="icon-twitter" /></a></li>
            <li className="ftco-animate"><a href={contatos.linkFacebook}><span className="icon-facebook" /></a></li>
            <li className="ftco-animate"><a href={contatos.linkInstagram}><span className="icon-instagram" /></a></li>
          </ul>
        </div>
      </div>
      <div className="col-md">
        <div className="ftco-footer-widget mb-4 ml-md-5">
          <h2 className="ftco-heading-2">Áreas de atuação</h2>
          <ul className="list-unstyled">
              <li><a className="py-1 d-block"><span className="ion-ios-arrow-forward mr-3" />Lei de família</a></li>
              <li><a className="py-1 d-block"><span className="ion-ios-arrow-forward mr-3" />Trabalhista</a></li>
              <li><a className="py-1 d-block"><span className="ion-ios-arrow-forward mr-3" />Seguros</a></li>
              <li><a className="py-1 d-block"><span className="ion-ios-arrow-forward mr-3" />Criminalista</a></li>
              <li><a className="py-1 d-block"><span className="ion-ios-arrow-forward mr-3" />Propriedades</a></li>
              <li><a className="py-1 d-block"><span className="ion-ios-arrow-forward mr-3" />Finanças</a></li>
              <li><a className="py-1 d-block"><span className="ion-ios-arrow-forward mr-3" />Ofensas</a></li>
              <li><a className="py-1 d-block"><span className="ion-ios-arrow-forward mr-3" />Ofensas sexuais</a></li>
          
          </ul>
        </div>
      </div>
      
    </div>
    <div className="row">
    <div className="col-md" >
        <div className="ftco-footer-widget mb-4">
          <h2 className="ftco-heading-2">Tem alguma dúvida?</h2>
          <div className="block-23 mb-3">
            <ul>
              <li><span className="icon icon-map-marker" /><span className="text">{contatos.endereco}</span></li>
              <li><a href="https://wa.me/+5518997321170" target="_blank"><span className="icon icon-phone" /><span className="text">{contatos.whatsapp}</span></a></li>
              <li><span className="icon icon-envelope" style={{ marginRight: '5px' }}/><span className="text"> {contatos.email}</span></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-md-5">
        <div className="ftco-footer-widget mb-4">
          <h2 className="ftco-heading-2">Horario de funcionamento</h2>
          <div className="opening-hours">
            <h4>Aberto:</h4>
            <p className="pl-3">
              <span>{contatos.funcionamento}</span>
              
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12 text-center">
        <p>{/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
          Copyright © 2024 Todos os direitos reservados | Este modelo é feito com <a href="https://colorlib.com" target="_blank"><u>Colorlib</u></a>
          {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}</p>
          <p>
          Site desenvolvido por <a href="https://www.linkedin.com/in/johnnovaes?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3B5YApW67kRRCeqQcGmTAyBQ%3D%3D" target="_blank"><u>Jonathan Novaes</u></a>
          </p>
      </div>
    </div>
  </div>
</footer>


    )
}

export default Rodape;