import React, { useEffect, useState } from "react";
import AdminHeader from "../componentes/AdminHeader";
import AdminNavBar from "../componentes/AdminNavBar";
import { createGlobalStyle } from  "styled-components";
import axios from 'axios';
import Loader from "../componentes/Loader";
import Cookies from 'js-cookie';

export const GlobalStyle = createGlobalStyle`
:root{
  --header-height: 3rem;
  --nav-width: 68px;
  --first-color: #afa939;
  --first-color-light: #ffffff;
  --black-color: #000000;
  --body-font: 'Nunito', sans-serif;
  --normal-font-size: 1rem;
  --z-fixed: 100}*,::before,::after{box-sizing: border-box}
  
  body{
      position: relative;
      margin: var(--header-height) 0 0 0;
      padding: 0 1rem;
      font-family: var(--body-font);
      font-size: var(--normal-font-size);
      transition: .5s}
      a{text-decoration: none}
      .header{
          width: 100%;
          height: var(--header-height);
          position: fixed;top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 1rem;
          background-color: var(--black-color);
          z-index: var(--z-fixed);
          transition: .5s}
          .header_toggle{color: var(--first-color);
              font-size: 1.5rem;cursor: pointer}
              .header_img{
                  width: 35px;
                  height: 35px;
                  display: flex;
                  justify-content: center;
                  border-radius: 50%;
                  overflow: hidden}
                  .header_img img{width: 40px}
                  .l-navbar{
                      position: fixed;
                      top: 0;
                      left: -30%;
                      width: var(--nav-width);
                      height: 100vh;
                      background-color: var(--first-color);
                      padding: .5rem 1rem 0 0;
                      transition: .5s;
                      z-index: var(--z-fixed)}
                      .nav{
                          height: 100%;
                          display: flex;
                          flex-direction: column;
                          justify-content: space-between;
                          overflow: hidden}
                          .nav_logo,
                           .nav_link{
                              display: grid;
                              grid-template-columns: max-content max-content;
                              align-items: center;
                              column-gap: 1rem;
                              padding: .5rem 0 .5rem 1.5rem}
                              .nav_logo{
                                  margin-bottom: 2rem}
                              .nav_logo-icon{
                                  font-size: 1.25rem;
                                  color: var(--black-color)}
                                  .nav_logo-name{
                                      color: var(--black-color);
                                      font-weight: 700}
                                      .nav_link{
                                          position: relative;
                                          color: var(
                                              --first-color-light);
                                              margin-bottom: 1.5rem;
                                              transition: .3s}
                                          .nav_link:hover{
                                              color: var(--black-color)}
                                              .nav_icon{
                                                  font-size: 1.25rem}
                                                  .show{
                                                      left: 0}
                                                      .body-pd{
                                                          padding-left: calc(var(--nav-width) + 1rem)}
                                                          .active{color: var(--black-color)}
                                                          .active::before{content: '';
                                                          position: absolute;
                                                          left: 0;
                                                          width: 2px;
                                                          height: 32px;
                                                          background-color: var(--black-color)}
                                                          .height-100{height:100vh}
                                                          @media screen and (min-width: 768px)
                                                          {
                                                              body{
                                                              margin: calc(var(--header-height) + 1rem) 0 0 0;
                                                              padding-left: calc(var(--nav-width) + 2rem)}
                                                              .header{height: calc(var(--header-height) + 1rem);
                                                              padding: 0 2rem 0 calc(var(--nav-width) + 2rem)}
                                                              .header_img{width: 40px;height: 40px}
                                                              .header_img img{width: 45px}
                                                              .l-navbar{left: 0;padding: 1rem 1rem 0 0}
                                                              .show{width: calc(var(--nav-width) + 156px)}
                                                              .body-pd{padding-left: calc(var(--nav-width) + 188px)}}
`

function Cabecalho(){

    const [rotacao, setRotacao] = useState('');
    const [rotacoes, setRotacoes] = useState([]);
    const [cabecalho, setCabecalho] = useState({
      titulo: null,
      subTitulo: null,
      tituloPrimeiroCard: null,
      primeiroCard: null,
      tituloSegundoCard: null,
      segundoCard: null,
      tituloTerceiroCard: null,
      terceiroCard: null,
      textoCabelhoRodape: null,
      tituloFixo: null
    })
    const token = Cookies.get();
    const [auth, setAuth] = useState(false);

    useEffect(() => {
      axios.post('/api/autenticacao', {token}, {withCredentials: true}).then((res)=>{
      setAuth(true)
    }).catch((error)=>{
      document.location = '/admin/login'
    })
      axios.get(`/api/buscar/cabecalho`).then((response) => {
	if(response.data.cabecalho){
        setCabecalho(response.data.cabecalho)}
        let loadRotacoes = []
        if(Array.isArray(response.data.rotacoes)){
          for(var cont=0; cont<response.data.rotacoes.length; cont++){
              loadRotacoes.push(response.data.rotacoes[cont].texto)
          }
        }
        setRotacoes(loadRotacoes)
      }).catch((error) => {
        console.log(error);
      });
    }, []);

    if(!auth){
    return <Loader />
  }

    function inputRotacao(event) {
        const { value } = event.target;
        console.log(rotacao)
        setRotacao(value);
      }
    
    function adicionarRotacoes(){
        setRotacoes([...rotacoes, rotacao]);
    }

    function deletarRotacao(index){
        console.log('acionou')
        const novaLista = [...rotacoes];
        novaLista.splice(index, 1); // Remove o elemento no índice especificado
        setRotacoes(novaLista); // Atualiza o estado com a nova lista
    }

    async function enviar() {
    
        const titulo = document.querySelector('#titulo').value;
        const subtitulo = document.querySelector('#subtitulo').value;
        const tituloPrimeiroCard = document.querySelector('#tituloPrimeiroCard').value
        const primeiroCard = document.querySelector('#textoPrimeiroCard').value
        const tituloSegundoCard = document.querySelector('#tituloSegundoCard').value
        const segundoCard = document.querySelector('#textoSegundoCard').value
        const tituloTerceiroCard = document.querySelector('#tituloTerceiroCard').value
        const terceiroCard = document.querySelector('#textoTerceiroCard').value
        const textoCabecalhoRodape = document.querySelector('#textoCabecalhoRodape').value
        const tituloFixo = document.querySelector('#tituloFixo').value
        try {
          const formData = {
            titulo: titulo,
            subTitulo : subtitulo ,
            tituloPrimeiroCard: tituloPrimeiroCard,
            primeiroCard: primeiroCard,
            tituloSegundoCard: tituloSegundoCard,
            segundoCard: segundoCard,
            tituloTerceiroCard: tituloTerceiroCard,
            terceiroCard: terceiroCard,
            rotacoes: rotacoes,
            textoCabecalhoRodape: textoCabecalhoRodape,
            tituloFixo: tituloFixo,
	    token: token
          }
    
          const response = await axios.post(`/api/cabecalho`, formData, {withCredentials: true});
          console.log('------------------------>')
          window.location = '/admin/cabecalho'
          console.log('------------------------>')
          // Lógica de tratamento em caso de sucesso
        } catch (error) {
          console.log('error: '+error)
          alert('Erro ao enviar o formulário:', error);
          // Lógica de tratamento em caso de erro
        }
      }; 
      
      function allInputChange(event) {
        const { name, value } = event.target;
        setCabecalho(prevState => ({
          ...prevState,
          [name]: value
        }));
      }

    return(
      <>
      <GlobalStyle />
        <div>
          <AdminHeader/>
          <AdminNavBar/>
               
        {/*Container Main start*/}
<div className="height-100 bg-light" id="admin">
  <div className="card" id="editor">
    <div className="card-header text-white bg-dark text-center">
      Cabeçalho
    </div>
    <div className="card-body" style={{backgroundColor: 'whitesmoke'}}>
      <div className="container">
        <div className="row">
            <div className="col-6">
                <label>Titulo do cabecalho</label>
                <input type="text" className="form-control" name="titulo" value={cabecalho.titulo !== null ? cabecalho.titulo : ''} onChange={allInputChange} id="titulo" />
            </div>
            <div className="col-6">
                <label>Subtítulo do cabeçalho</label>
                <input type="text" className="form-control" name="subTitulo" value={cabecalho.subTitulo !== null ? cabecalho.subTitulo : ''} onChange={allInputChange} id="subtitulo" />
            </div>
        </div>
        <hr></hr>
        <div className="row">
            <div className="col-6">
                <label>Titulo do primeiro card</label>
                <input type="text" className="form-control" name="tituloPrimeiroCard" value={cabecalho.tituloPrimeiroCard !== null ? cabecalho.tituloPrimeiroCard : ''} onChange={allInputChange} id="tituloPrimeiroCard"/>
            </div>
            <div className="col-6">
                <label>Texto do primeiro card</label>
                <textarea className="form-control" rows="6" name="primeiroCard" value={cabecalho.primeiroCard !== null ? cabecalho.primeiroCard : ''} onChange={allInputChange} id="textoPrimeiroCard" />
            </div>
        </div>
        <hr></hr>
        <div className="row">
            <div className="col-6">
                <label>Titulo do segundo card</label>
                <input type="text" className="form-control" name="tituloSegundoCard" value={cabecalho.tituloSegundoCard !== null ? cabecalho.tituloSegundoCard : ''} onChange={allInputChange} id="tituloSegundoCard" />
            </div>
            <div className="col-6">
                <label>Texto do segundo card</label>
                <textarea className="form-control" rows="6" name="segundoCard" value={cabecalho.segundoCard !== null ? cabecalho.segundoCard : ''} onChange={allInputChange} id="textoSegundoCard" />
            </div>
        </div>
        <hr></hr>
        <div className="row">
            <div className="col-6">
                <label>Titulo do terceiro card</label>
                <input type="text" className="form-control" name="tituloTerceiroCard" value={cabecalho.tituloTerceiroCard !== null ? cabecalho.tituloTerceiroCard : ''} onChange={allInputChange} id="tituloTerceiroCard"/>
            </div>
            <div className="col-6">
                <label>Texto do terceiro card</label>
                <textarea className="form-control" rows="6" name="terceiroCard" value={cabecalho.terceiroCard !== null ? cabecalho.terceiroCard : ''} onChange={allInputChange} id="textoTerceiroCard" />
            </div>
        </div>
        <hr></hr>
        <div className="row">
          <label>Título fixo</label>
          <div className="col-12"> 
            <input id="tituloFixo" className="form-control" type="text" name="tituloFixo" value={cabecalho.tituloFixo !== null ? cabecalho.tituloFixo : ''} onChange={allInputChange} />
          </div>
          
        </div>
        <div className="row">
            <div className="col-6">
                <label>Rotação</label>
                <div class="input-group mb-3">
                  <input type="text" class="form-control" name="rotacao" value={rotacao}  onChange={inputRotacao} />
                  <button class="btn btn-outline-secondary" type="button" id="button-addon2" onClick={adicionarRotacoes}><span className="icon-plus"> Adicionar</span></button>
                </div>
            </div>
            <div className="col-6">
                <label>Texto do cabecalho e rodapé</label>
                <textarea className="form-control" rows="6" name="textoCabelhoRodape" value={cabecalho.textoCabelhoRodape !== null ? cabecalho.textoCabelhoRodape: ''} onChange={allInputChange} id="textoCabecalhoRodape" />
            </div>
        </div>
        <hr></hr>
        <div className="row">
        <div className="col-6">
  <label>Rotações selecionadas</label>
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    {rotacoes.map((res, index) => (
      <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ margin: '10px' }}>{res}</span>
        <button className="btn btn-danger" style={{ marginLeft: '5px' }} onClick={()=>deletarRotacao(index)}>
          <span className="icon-trash"></span>
        </button>
      </div>
    ))}
  </div>
</div>
        </div>
       <hr></hr> 
        <div className="row" style={{marginTop: 5}}>
          <div className="col-12"> 
            <button className="btn bg-info text-white" type="button" onClick={enviar}>Confirmar</button>
            <button style={{marginLeft: 5}} className="btn bg-danger text-white" type="button" onClick={()=>window.location ='/admin/blog'}>Cancelar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

        </div>
        </>
    )
}

export default Cabecalho;

