import React, { useEffect, useState } from "react";
import NavBar from "../componentes/NavBar";
import Header from "../componentes/Header";
import Servicos from "../componentes/Servicos";
import Missao from "../componentes/Missao";
import Depoimentos from "../componentes/Depoimentos";
import Rodape from "../componentes/Rodape";
import Loader from "../componentes/Loader";
import axios from "axios";
import { Helmet } from 'react-helmet';

function Home() {
  const [resp, setResp] = useState(false);
  const [rotacoes, setRotacoes] = useState([]);
  const [cabecalho, setCabecalho] = useState(false);
  const [missao, setMissao] = useState(false);
  const [depoimentos, setDepoimentos] = useState(false);
  const [contatos, setContatos] = useState(false);
  const [areasAtuacao, setAreasAtuacao] = useState(false);
  

  useEffect(() => {
    axios.post('/api/dadosGerais').then((response) => {
      console.log(response.data);
      setResp(response.data);
      const novasRotacoes = response.data.rotacao.map((res) => res.texto);
      setRotacoes(novasRotacoes);
      console.log('-------------')
      console.log(response.data.cabecalho);
      console.log('-------------')
      setCabecalho(response.data.cabecalho);
      setMissao(response.data.missao);
      setDepoimentos(response.data.depoimentos);
      setContatos(response.data.contatos);
      setAreasAtuacao(response.data.areaAtuacao);
      setResp(true)
    }).catch((error) => {
      console.log(error);
    });
  }, []);

  if(!cabecalho || rotacoes.length==0 || !missao || !depoimentos || !contatos || !areasAtuacao){
    return <Loader />
  }

  return (
    <>
    <Helmet>
        <title>Douglas Cruz | Advogado</title>
        <meta name="description" content={'Advogado especializado em fornecer consultoria jurídica personalizada e representação legal eficaz em uma variedade de áreas, incluindo direito civil, criminal, trabalhista e empresarial. Oferecemos soluções legais abrangentes e dedicadas para proteger os interesses e os direitos dos nossos clientes.'} />
        <meta name="keywords" content={`advogado, advocacia, direito civil, direito criminal, direito trabalhista, direito empresarial, consultoria jurídica, representação legal, defesa legal, litígio, jurisprudência, tribunal, contrato, litígio, mediação, arbitragem`} />
      </Helmet>
    <NavBar cabecalho={cabecalho} contatos={contatos} />
      <Header rotacao={rotacoes} cabecalho={cabecalho}/>
      <Servicos cabecalho={cabecalho} contatos={contatos} />
      <Missao missao={missao} />
      <Rodape contatos={contatos} cabecalho={cabecalho} areasAtuacao={areasAtuacao} />
    </>
  );
}

export default Home;
