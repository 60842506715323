import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';

function AdminNavBar(){

  const location = useLocation();

  useEffect(() => {
    const storedValue = localStorage.getItem('isChecked');
    console.log('-=-=-=-=-=-=-')
    console.log(JSON.parse(storedValue))
    const showNavbar = (toggleId, navId, bodyId, headerId) => {
        const toggle = document.getElementById(toggleId),
            nav = document.getElementById(navId),
            bodypd = document.getElementById(bodyId),
            headerpd = document.getElementById(headerId);

        // Validate that all variables exist
        if (toggle && nav && bodypd && headerpd) {
            
            if(JSON.parse(storedValue)){
              console.log('LocalStorage')
              // show navbar
              nav.classList.toggle('show');
              // change icon
              toggle.classList.toggle('bx-x');
              // add padding to body
              bodypd.classList.toggle('body-pd');
              // add padding to header
              headerpd.classList.toggle('body-pd');
            }
            toggle.addEventListener('click', () => {
                // show navbar
                nav.classList.toggle('show');
                // change icon
                toggle.classList.toggle('bx-x');
                // add padding to body
                bodypd.classList.toggle('body-pd');
                // add padding to header
                headerpd.classList.toggle('body-pd');
            });
        }
    }

    showNavbar('header-toggle', 'nav-bar', 'body-pd', 'header');

    /*===== LINK ACTIVE =====*/
    const linkColor = document.querySelectorAll('.nav_link');

    function colorLink() {
        if (linkColor) {
            linkColor.forEach(l => l.classList.remove('active'))
            this.classList.add('active')
        }
    }

    linkColor.forEach(l => l.addEventListener('click', colorLink));

    // Your code to run since DOM is loaded and ready
}, []); // empty dependency array means this effect runs once after the component mounts

 

  return (
   <div className={`l-navbar`} id="nav-bar">
  <nav className="nav">
    <div> 
      <a href="#" className="nav_logo"> 
        <i className="bx bx-layer nav_logo-icon" /> 
        <span className="nav_logo-name">Admin</span> 
      </a>
      <div className="nav_list"> 
        <a href="#" className={`nav_link ${location.pathname=='/admin'? 'active': ''}`}> 
          <i className="bx bx-grid-alt nav_icon" /> 
          <span className="nav_name">Início</span> 
        </a> <a href="/admin/blog" className={`nav_link ${location.pathname.includes('/admin/blog')? 'active': ''}`}> 
          <i className="bx bx-spreadsheet nav_icon" /> 
          <span className="nav_name">Blog</span> 
        </a> 
        <a href="/admin/depoimento" className={`nav_link ${location.pathname.includes('/admin/depoimento')? 'active': ''}`}> 
          <i className="bx bx-bookmark nav_icon" /> 
          <span className="nav_name">Depoimentos</span> 
        </a> 
        <a href="/admin/contatos" className={`nav_link ${location.pathname.includes('/admin/contatos')? 'active': ''}`}> 
          <i className="bx bx-phone nav_icon" /> 
          <span className="nav_name">Contatos</span> 
        </a> 
        <a href="/admin/quemSomos" className={`nav_link ${location.pathname.includes('/admin/quemSomos')? 'active': ''}`}> 
          <i className="bx bx-file nav_icon" /> 
          <span className="nav_name">Quem somos</span> 
        </a> 
        <a href="/admin/cabecalho" className={`nav_link ${location.pathname.includes('/admin/cabecalho')? 'active': ''}`}> 
          <i className="bx bx-task nav_icon" /> 
          <span className="nav_name">Cabeçalho</span> 
        </a> 
        <a href="#" className="nav_link"> 
          <i className="bx bx-log-out nav_icon" /> 
          <span className="nav_name">Sair</span>
        </a>
      </div>
    </div> 
  </nav>
</div>
  );
}


export default AdminNavBar;