import React from "react";

function Missao({missao}){

    return (
      <section className="ftco-section ftco-no-pt ftco-no-pb">
  <div className="container">
    <div className="row d-flex">
      <div className="col-md-6 d-flex">
        <div className="img img-video d-flex align-self-stretch align-items-center justify-content-center justify-content-md-end" style={{backgroundImage: 'url(images/doug-2.jpg)'}}>
        </div>
      </div>
      <div className="col-md-6 pl-md-5">
        <div className="row justify-content-start pt-3 pb-3">
          <div className="col-md-12 heading-section ftco-animate">
            <span className="subheading">Douglas Cruz</span>
            <h2 className="mb-4">{missao.titulo}</h2>
            <p style={{textAlign: 'justify'}}>{missao.descricao}</p>
            <div className="tabulation-2 mt-4">
              <ul className="nav nav-pills nav-fill d-md-flex d-block">
                <li className="nav-item mb-md-0 mb-2">
                  <a className="nav-link active py-2" data-toggle="tab" href="#home1">Nossa missão</a>
                </li>
                <li className="nav-item px-lg-2 mb-md-0 mb-2">
                  <a className="nav-link py-2" data-toggle="tab" href="#home2">Nossa visão</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link py-2 mb-md-0 mb-2" data-toggle="tab" href="#home3">Nossos valores</a>
                </li>
              </ul>
              <div className="tab-content bg-light rounded mt-2">
                <div className="tab-pane container p-0 active" id="home1">
                  <p style={{textAlign: 'justify'}}>{missao.missao}</p>
                </div>
                <div className="tab-pane container p-0 fade" id="home2">
                  <p style={{textAlign: 'justify'}}>{missao.valores}</p>
                </div>
                <div className="tab-pane container p-0 fade" id="home3">
                  <p style={{textAlign: 'justify'}}>
                    {missao.visao}</p>
                </div>
              </div>
            </div>
            <div className="years d-flex mt-4 mt-md-5">
              <h4>
                <span style={{marginRight: '5px'}} className="icon-google">oogle</span>
                <span className="number mr-2" data-number={5}>0</span>
                <span className="icon-star" />
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


    )
}

export default Missao