import React, { useEffect, useState } from "react";
import AdminHeader from "../componentes/AdminHeader";
import AdminNavBar from "../componentes/AdminNavBar";
import { createGlobalStyle } from  "styled-components";
import axios from 'axios'
import Loader from "../componentes/Loader";
import Cookies from 'js-cookie';

export const GlobalStyle = createGlobalStyle`
:root{
  --header-height: 3rem;
  --nav-width: 68px;
  --first-color: #afa939;
  --first-color-light: #ffffff;
  --black-color: #000000;
  --body-font: 'Nunito', sans-serif;
  --normal-font-size: 1rem;
  --z-fixed: 100}*,::before,::after{box-sizing: border-box}
  
  body{
      position: relative;
      margin: var(--header-height) 0 0 0;
      padding: 0 1rem;
      font-family: var(--body-font);
      font-size: var(--normal-font-size);
      transition: .5s}
      a{text-decoration: none}
      .header{
          width: 100%;
          height: var(--header-height);
          position: fixed;top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 1rem;
          background-color: var(--black-color);
          z-index: var(--z-fixed);
          transition: .5s}
          .header_toggle{color: var(--first-color);
              font-size: 1.5rem;cursor: pointer}
              .header_img{
                  width: 35px;
                  height: 35px;
                  display: flex;
                  justify-content: center;
                  border-radius: 50%;
                  overflow: hidden}
                  .header_img img{width: 40px}
                  .l-navbar{
                      position: fixed;
                      top: 0;
                      left: -30%;
                      width: var(--nav-width);
                      height: 100vh;
                      background-color: var(--first-color);
                      padding: .5rem 1rem 0 0;
                      transition: .5s;
                      z-index: var(--z-fixed)}
                      .nav{
                          height: 100%;
                          display: flex;
                          flex-direction: column;
                          justify-content: space-between;
                          overflow: hidden}
                          .nav_logo,
                           .nav_link{
                              display: grid;
                              grid-template-columns: max-content max-content;
                              align-items: center;
                              column-gap: 1rem;
                              padding: .5rem 0 .5rem 1.5rem}
                              .nav_logo{
                                  margin-bottom: 2rem}
                              .nav_logo-icon{
                                  font-size: 1.25rem;
                                  color: var(--black-color)}
                                  .nav_logo-name{
                                      color: var(--black-color);
                                      font-weight: 700}
                                      .nav_link{
                                          position: relative;
                                          color: var(
                                              --first-color-light);
                                              margin-bottom: 1.5rem;
                                              transition: .3s}
                                          .nav_link:hover{
                                              color: var(--black-color)}
                                              .nav_icon{
                                                  font-size: 1.25rem}
                                                  .show{
                                                      left: 0}
                                                      .body-pd{
                                                          padding-left: calc(var(--nav-width) + 1rem)}
                                                          .active{color: var(--black-color)}
                                                          .active::before{content: '';
                                                          position: absolute;
                                                          left: 0;
                                                          width: 2px;
                                                          height: 32px;
                                                          background-color: var(--black-color)}
                                                          .height-100{height:100vh}
                                                          @media screen and (min-width: 768px)
                                                          {
                                                              body{
                                                              margin: calc(var(--header-height) + 1rem) 0 0 0;
                                                              padding-left: calc(var(--nav-width) + 2rem)}
                                                              .header{height: calc(var(--header-height) + 1rem);
                                                              padding: 0 2rem 0 calc(var(--nav-width) + 2rem)}
                                                              .header_img{width: 40px;height: 40px}
                                                              .header_img img{width: 45px}
                                                              .l-navbar{left: 0;padding: 1rem 1rem 0 0}
                                                              .show{width: calc(var(--nav-width) + 156px)}
                                                              .body-pd{padding-left: calc(var(--nav-width) + 188px)}}
`

function CriarDepoimento(){

  const token = Cookies.get();
  const [auth, setAuth] = useState(false);

  useEffect(() => {
    
    axios.post('/api/autenticacao', {token}, {withCredentials: true}).then((res)=>{
      setAuth(true)
    }).catch((error)=>{
      document.location = '/admin/login'
    })
  }, []);

  if(!auth){
    return <Loader />
  }

  async function enviar() {
    
    const arquivoSelecionado = document.querySelector('#imagem-depoimento').files[0];
    const nome = document.querySelector('#nome').value;
    const profissao = document.querySelector('#profissao').value;
    const depoimento = document.querySelector('#depoimento').value

    if (!arquivoSelecionado) {
      alert('Selecione um arquivo antes de enviar.');
      return;
    }

    if (!nome) {
      alert('Descreva um nome antes de enviar.');
      return;
    }

    if (!profissao) {
      alert('Descreva uma profissão antes de enviar.');
      return;
    }

    if (!depoimento) {
      alert('Descreva um depoimento antes de enviar.');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('upload', arquivoSelecionado);
      formData.append('nome', nome);
      formData.append('profissao', profissao);
      formData.append('depoimento', depoimento);
      formData.append('token', token);
      const response = await axios.post(`/api/novo/depoimento`, formData, {withCredentials: true, headers: {
        "Content-Type": "multipart/form-data"
      }});
      console.log('------------------------>')
      window.location = '/admin/depoimento'
      console.log('------------------------>')
      // Lógica de tratamento em caso de sucesso
    } catch (error) {
      console.log('error: '+error)
      alert('Erro ao enviar o formulário:', error);
      // Lógica de tratamento em caso de erro
    }
  };  
  
    return(
      <>
      <GlobalStyle />
        <div>
          <AdminHeader/>
          <AdminNavBar/>
               
        {/*Container Main start*/}
<div className="height-100 bg-light" id="admin">
  <div className="card" id="editor">
    <div className="card-header text-white bg-dark">
      Novo Depoimento
    </div>
    <div className="card-body">
      <div className="container">
      <div className="row">
          <div className="col-12">
            <label>Imagem</label>
            <input type="file" className="form-control" id="imagem-depoimento"/>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <label>Nome do cliente</label>
            <input type="text" className="form-control" id="nome" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <label>Profissão</label>
            <input type="text" className="form-control" id="profissao" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <label>Depoimento</label>
            <textarea className="form-control" id="depoimento"></textarea>
          </div>
        </div>
        <div className="row" style={{marginTop: 5}}>
          <div className="col-12"> 
            <button className="btn bg-info text-white" type="button" onClick={enviar}>Confirmar</button>
            <button style={{marginLeft: 5}} className="btn bg-danger text-white" type="button" onClick={()=>window.location ='/admin/blog'}>Cancelar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

        </div>
        </>
    )
}

export default CriarDepoimento;
