import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';

function NavBar({cabecalho, contatos}){
  const location = useLocation();
  

  return(
    <>
 <nav className="navbar px-md-0 navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">
  <div className="container">
    <a className="navbar-brand" href="/">{cabecalho.titulo} <span>{cabecalho.subTitulo}</span></a>
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="oi oi-menu" /> Menu
    </button>
    <div className="collapse navbar-collapse" id="ftco-nav">
      <ul className="navbar-nav ml-auto">
        <li className={`nav-item ${location.pathname=='/'? 'active': ''}`}><a href="/" className="nav-link">Início</a></li>
        <li className={`nav-item ${location.pathname.includes('/areasAtuacao')? 'active': ''}`}><a href="/areasAtuacao" className="nav-link">Áreas de atuação</a></li>
        <li className={`nav-item ${location.pathname.includes('/blog')? 'active': ''}`}><a href="/blog" className="nav-link">Blog</a></li>
        <li className="nav-item cta"><a href={`https://wa.me/${contatos.whatsapp}?text=${contatos.textoWhatsapp}` }target="_blank" className="nav-link"><span className="icon-whatsapp" /> Whatsapp</a></li>
      </ul>
    </div>
  </div>
</nav>
{/* END nav */}

    </>
  )
}

export default NavBar