import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from 'react-helmet';


function CardCategoriaBlog(){
  const { tema } = useParams();
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    axios.get(`/api/todos/artigos/categoria/${tema}`).then((response) => {
      console.log('inicio')
      console.log(response.data.blogs)
      setBlogs(response.data.blogs);
    }).catch((error) => {
      console.log(error);
    });
  }, []);

    // Exemplo de lista de artigos
  const allArticles = blogs;

  // Estado para controlar a página atual de artigos
  const [currentPage, setCurrentPage] = useState(1);
  let articlesPerPage; // Número de artigos por página
  if(blogs.length<6){
    articlesPerPage = blogs.length
  }
  else{
    articlesPerPage = 6
  }
  // Calcula o índice inicial e final dos artigos a serem exibidos na página atual
  const indexOfLastArticle = Math.min(currentPage * articlesPerPage, allArticles.length);
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;

  console.log(indexOfFirstArticle)
  console.log(indexOfLastArticle)
  const currentArticles = allArticles.slice(indexOfFirstArticle, indexOfLastArticle);
  console.log('=======>AAAAA')
  console.log(currentArticles)
  
  // Função para exibir os próximos artigos
  const nextPage = () => {
    if (currentPage < allArticles.length/6) {
      setCurrentPage(currentPage + 1);
      console.log(currentPage+1)
    }
  };

  // Função para exibir os artigos com base no número
  const numberPage = (index) => {
      console.log('index: ', index)
      setCurrentPage(index);
  };

  // Função para exibir os artigos anteriores
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      console.log(currentPage-1)
    }
  };

  const renderedItems = [];

  // Para estilos de hover
  const linkHoverStyle = {
    cursor: 'pointer',
  };

  for (let i = 1; i <= Math.ceil(allArticles.length/6); i++) {
    // Adicione um elemento JSX ao array com uma chave única
    renderedItems.push(<li className={currentPage== i ? "active": ''}><span onClick={()=>numberPage(i)} style={linkHoverStyle}>{i}</span></li>);
  }

  const monthNames = [
    'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
    'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
  ];
  

    return(
      <>
        <Helmet>
        <title>{tema}</title>
        <meta name="description" content={'Advogado especializado em fornecer consultoria jurídica personalizada e representação legal eficaz em uma variedade de áreas, incluindo direito civil, criminal, trabalhista e empresarial. Oferecemos soluções legais abrangentes e dedicadas para proteger os interesses e os direitos dos nossos clientes.'} />
        <meta name="keywords" content={`advogado, advocacia, direito civil, direito criminal, direito trabalhista, direito empresarial, consultoria jurídica, representação legal, defesa legal, litígio, jurisprudência, tribunal, contrato, litígio, mediação, arbitragem`} />
      </Helmet>
        <section className="ftco-section bg-light">
  <div className="container">
    <div className="row d-flex">
        {currentArticles.map((article, index) => (

          <div className="col-md-4 d-flex">
          <div className="blog-entry justify-content-end">
            <div className="text px-4 py-4">
              <h3 className="heading mb-0"><a href={`/blog/artigo/${article.parametro}`}>{article.titulo}</a></h3>
            </div>
            <a href={`/blog/artigo/${article.parametro}`} className="block-20" style={{backgroundImage: `url(http://douglascruzadvogado.com.br/api/imagem/${article.dirimg})`}}>
            </a>
            <div className="text p-4 float-right d-block">
              <div className="topper d-flex align-items-center">
                <div className="one py-2 pl-3 pr-1 align-self-stretch">
                  <span className="day">{new Date(article.updatedAt).getDate()}</span>
                </div>
                <div className="two pl-0 pr-3 py-2 align-self-stretch">
                  <span className="yr">{new Date(article.updatedAt).getFullYear()}</span>
                  <span className="mos">{monthNames[new Date(article.updatedAt).getMonth()]}</span>
                </div>
              </div>
              <p style={{ width: '280px' }}>{article.descricao}</p>
              <p><a href={`/blog/artigo/${article.parametro}`} className="btn btn-primary">Leia mais</a></p>
            </div>
          </div>
        </div>
        ))}
    </div>
    <div className="row mt-5">
      <div className="col text-center">
        <div className="block-27">
          <ul>
            <li><a onClick={prevPage} disabled={currentPage === 1} style={linkHoverStyle}>&lt;</a></li>
            {renderedItems}
            <li><span onClick={nextPage} disabled={indexOfLastArticle >= allArticles.length/6} style={linkHoverStyle}>&gt;</span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
</>
    )
}

export default CardCategoriaBlog;
