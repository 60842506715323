function HeaderBlog(){


    return(
        <section className="hero-wrap hero-wrap-2" style={{backgroundImage: 'url("/images/doug-1.jpg")'}} data-stellar-background-ratio="0.5">
  <div className="overlay" />
  <div className="container">
    <div className="row no-gutters slider-text align-items-end justify-content-center">
      <div className="col-md-9 ftco-animate pb-5 text-center" id="admin">
        <h1 className="mb-3 bread">Blog</h1>
        <p className="breadcrumbs"><span className="mr-2"><a href="/">Início <i className="ion-ios-arrow-forward" /></a></span> <span>Blog <i className="ion-ios-arrow-forward" /></span></p>
      </div>
    </div>
  </div>
</section>

    )
}

export default HeaderBlog;