import { useEffect, useState } from "react"
import axios from "axios";
import { useParams } from "react-router-dom";
import { format } from 'date-fns';
import { Helmet } from 'react-helmet';

function BlogArtigo({cabecalho}){
    const { tema } = useParams();
    const [responseBlog, setResponse] = useState(false);
    const [responseBlogRecents, setResponseRecents] = useState([]);
    const [metaTags, setMetaTags] = useState([]);
    const [tagsGerais, setTagsGerais] = useState([]);
    const [metaDescricao, setMetaDescricao] = useState('')
    const [search, setSearch] = useState('')
    useEffect(()=>{
      axios.post(`/api/blog/${tema}`).then((response)=>{
  
        setResponse(response.data.blog)
        console.log('-=-=-=-=-=-=-=-=-=-=-=-=-')
        console.log(response.data.blogsRecentes)
        console.log(response.data.metaTags)
        setResponseRecents(response.data.blogsRecentes)
        setMetaTags(response.data.metaTags)
        setTagsGerais(response.data.tagsGerais)
        console.log('<------->')
        console.log(response.data.metaDescricao)
        setMetaDescricao(response.data.metaDescricao)
        
      }).catch((error)=>{
        console.log(error)
      })
    },[]);

    const handleInputChange = (event) => {
      // Atualizando o estado com o novo valor do input
      setSearch(event.target.value);
    };


    return(<> 
    <Helmet>
        <title>{responseBlog.titulo}</title>
        <meta name="description" content={`${responseBlog.descricao}`} />
        <meta name="keywords" content={`${metaTags.map((res)=>{return res.tema})}`} />
      </Helmet>
    <section className="ftco-section ftco-degree-bg">
  <div className="container">
    <div className="row">
      <div className="col-lg-8 ftco-animate fadeInUp ftco-animated">
        {responseBlog && (
        <div dangerouslySetInnerHTML={{ __html: responseBlog.postagem }} />
        )}
        <div class="tag-widget post-tag-container mb-5 mt-5">
              <div class="tagcloud">
                {metaTags.slice(0, 6).map((res)=>(
                  <a href={`/blog/categoria/${res.tema}`} class="tag-cloud-link">{res.tema}</a>
                ))}
              </div>
            </div>
        <div class="about-author d-flex p-4 bg-light">
              <div class="bio mr-5">
                <img src="http://douglascruzadvogado.com.br/images/doug-1.jpg" alt="Image placeholder" width="500" height="350" class="img-fluid mb-4"/>
              </div>
              <div class="desc">
                <h3>{cabecalho.titulo}</h3>
                <p>{cabecalho.textoCabelhoRodape}</p>
              </div>
            </div>
      </div> {/* .col-md-8 */}
      <div className="col-lg-4 sidebar pl-lg-5 ftco-animate">
        <div className="sidebar-box">
          <form action={`/blog/categoria/${search}`} className="search-form">
            <div className="form-group">
              <span className="icon icon-search" />
              <input type="text" className="form-control" value={search} onChange={handleInputChange} placeholder="Pesquise um artigo por aqui" />
            </div>
          </form>
        </div>
        <div className="sidebar-box ftco-animate">
          <div className="categories">
            <h3>Categorias</h3>
            {tagsGerais.slice(0, 10).map((res)=>(
            <li><a href={`/blog/categoria/${res.tema}`}>{res.tema}<span className="ion-ios-arrow-forward" /></a></li>
            ))}
          </div>
        </div>
        <div className="sidebar-box ftco-animate">
          <h3>Artigos recentes</h3>
          {responseBlogRecents.map((res, index)=>(
            <div className="block-21 mb-4 d-flex">
              <a className="blog-img mr-4" style={{backgroundImage: `url(http://douglascruzadvogado.com.br/api/imagem/${res.dirimg})`}} />
              <div className="text">
              <h3 className="heading"><a href={`/blog/artigo/${res.parametro}`}>{res.titulo}</a></h3>
              <div className="meta">
                <div><a href={`/blog/artigo/${res.parametro}`}><span className="icon-calendar" /> {format(new Date(res.updatedAt), 'dd/MM/yyyy HH:mm:ss')}</a></div>
                <div><a href={`/blog/artigo/${res.parametro}`}><span className="icon-person" /> {cabecalho.titulo}</a></div>
              </div>
            </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
</section> 
    </>
       

    )
}

export default BlogArtigo
