

function Header({rotacao, cabecalho}){

  

    return (
     <div className="hero-wrap js-fullheight" style={{backgroundImage: 'url("images/doug-1.jpg")'}} data-stellar-background-ratio="0.5">
  <div className="overlay" />
  <div className="container">
    <div className="row no-gutters slider-text js-fullheight align-items-center justify-content-start" data-scrollax-parent="true">
      <div className="col-md-6 ftco-animate">
        <h2 className="subheading">Seja muito bem vindo!</h2>
        <h1>{cabecalho.tituloFixo}
          <span className="txt-rotate" style={{marginLeft: 10}} data-period={2000} data-rotate={JSON.stringify(rotacao)} />
        </h1>
        {/* <h1 class="mb-4">Attorneys Fighting For Your Freedom</h1> */}
        <p className="mb-4">{cabecalho.textoCabelhoRodape}</p>
      </div>
    </div>
  </div>
</div>



    )
}

export default Header
